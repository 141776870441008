<template>
  <v-container class="pa-0 fill-height">
    <v-row justify="center" align="center">
      <v-data-table
        :headers="headers"
        :items="all_cardiff"
        :loading="loading"
        sort-by="name"
        class="elevation-1"
        :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Entitées Cardiff</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              clearable
              hide-details
              class="mx-8"
              :background-color="search ? 'blue-grey darken-1' : '#272727'"
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" min-width="600px" :max-width="$vuetify.breakpoint.mdAndDown ? '100vw' : '50vw'">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on">Nouvelle entité cardiff</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.user" label="User"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.password"
                          :type="!showPassword ? 'password' : 'text'"
                          label="Password"
                          :append-icon="!showPassword ? 'mdi-eye' : 'mdi-eye-outline'"
                          @click:append="showPassword = !showPassword"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-combobox
                          v-model="editedItem.codes"
                          :items="[]"
                          class="mt-0 pt-1"
                          label="Codes"
                          multiple
                          chips
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.host" label="Host"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn
                    :disabled="!editedItem.user || !editedItem.password || editedItem.codes.length <= 0"
                    color="blue darken-1"
                    text
                    @click="save"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import snackBar from "@/utils/mixins/snackBar";

export default {
  name: "Company",

  mixins: [snackBar],

  data: () => ({
    all_cardiff: [],
    loading: false,

    search: "",
    dialog: false,
    mode: "new",
    headers: [
      { text: "Host", value: "host" },
      { text: "User", value: "user" },
      { text: "codes", value: "codes" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    defaultItem: {
      _id: "",
      host: "",
      codes: [],
      user: "",
      password: "",
    },
    editedItem: {
      _id: "",
      host: "",
      codes: [],
      user: "",
      password: "",
    },
    showPassword: false,
  }),

  async mounted() {
    this.initialize();
  },

  methods: {
    async getCardiff() {
      this.loading = true;
      try {
        this.all_cardiff = (await this.$axios.get("/all_vehicule_provider")).data;
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(`Couldn't retrieve cardiff infos ... (${error})`);
      } finally {
        this.loading = false;
      }
    },

    initialize() {
      this.getCardiff();
      if (this.$route.params.user) this.search = this.$route.params.user;
    },

    editItem(item) {
      this.mode = "edit";
      this.editedIndex = this.all_cardiff.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    async deleteItem(item) {
      const index = this.all_cardiff.indexOf(item);
      if (index >= 0 && confirm("Are you sure you want to delete this item?")) {
        try {
          await this.$axios.delete("/vehicule_provider", { data: { cardiffId: item._id } });
          this.all_cardiff.splice(index, 1);
        } catch (error) {
          console.error(error);
          this.displayErrorSnack(error);
        }
      }
    },

    close() {
      this.editedItem.password = "";
      this.showPassword = false;
      this.dialog = false;
      this.mode = "new";
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      if (this.editedIndex > -1) {
        //edit
        await this.handleEditCardiff();
      } else {
        //new
        await this.handleNewCardiff();
      }
      this.editedItem.password = "";
      this.showPassword = false;
      this.close();
    },

    async handleNewCardiff() {
      try {
        let cardiff = (
          await this.$axios.post("vehicule_provider", {
            user: this.editedItem.user,
            password: this.editedItem.password,
            codes: this.editedItem.codes,
            host: this.editedItem.host || undefined,
          })
        ).data;

        this.all_cardiff.push({ ...cardiff });
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
    },

    async handleEditCardiff() {
      try {
        let cardiff = await this.editCardiff();

        Object.assign(this.all_cardiff[this.editedIndex], cardiff);
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
    },

    async editCardiff() {
      try {
        let cardiff = (
          await this.$axios.put("vehicule_provider", {
            _id: this.editedItem._id,
            user: this.editedItem.user,
            password: this.editedItem.password,
            codes: this.editedItem.codes,
            host: this.editedItem.host || undefined,
          })
        ).data;
        return cardiff;
      } catch (error) {
        console.error(error);
        this.displayErrorSnack(error);
      }
    },
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>

<style lang="scss" scoped></style>
